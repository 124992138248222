export const categoryList = [
    {
        value: "companyNews",
        name: "公司新闻",
    },
    {
        value: "staffNews",
        name: "员工风采",
    },
    {
        value: "industryNews",
        name: "行业学术",
    },
]